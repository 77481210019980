import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { WidgetsModule } from '../widgets/widgets.module';
import { ManagePaymentModalContextComponent } from './manage-payment-modal-context/manage-payment-modal-context.component';
import { ManageAddressesModalComponent } from './manage-addresses-modal/manage-addresses-modal.component';
import { ConfirmMessageModalComponent } from './confirm-message-modal/confirm-message-modal.component';
import { ConfirmPasswordModalComponent } from './confirm-password-modal/confirm-password-modal.component';
import { InformationMessageModalComponent } from './information-message-modal/information-message-modal.component';
import { AddActivatedNumberModalComponent } from './add-activated-number-modal/add-activated-number-modal.component';
import { InputModalComponent } from './input-modal/input-modal.component';
import { RoutingModalComponent } from './routing-modal/routing-modal.component';
import { EditCcModalComponent } from './edit-cc-modal/edit-cc-modal.component';
import { SelectCCModalComponent } from './select-payment-modal/select-payment-modal.component';
import { ShippingAddressModalComponent } from './shipping-address-modal/shipping-address-modal.component';
import { SimReplacementModalComponent } from './sim-replacement-modal/sim-replacement-modal.component';
import { PhoneNotImpactedModalComponent } from './phone-not-impacted-modal/phone-not-impacted-modal.component';
import { SwiperModule } from 'swiper/angular';
import { AlertSecurityModalComponent } from './alert-security-modal/alert-security-modal.component';
import { IosDataSetupModalComponent } from './ios-data-setup-modal/ios-data-setup-modal.component';
import { CompatibilitySkipModalComponent } from './skip-device-modal/skip-device-modal.component';
import { eSimReplacementModalComponent } from './esim-replacement-modal/esim-replacement-modal.component';
import { TrackingModalComponent } from './tracking-details-modal/tracking-details-modal.component';
import { successModalComponent } from './success-modal/success-modal.component';
import { InstractionsFiveGIos17ModalComponent } from './instractions-five-g-ios17-modal/instractions-five-g-ios17-modal.component';
import { GoogleLoginComponent } from './google-login/google-login.component';
import { GoogleSigninButtonModule } from '@abacritt/angularx-social-login';
import { RecaptchaVerificationComponent } from './recaptcha-verification/recaptcha-verification.component';

@NgModule({
        declarations: [ConfirmMessageModalComponent, ManagePaymentModalContextComponent,
                ManageAddressesModalComponent, PhoneNotImpactedModalComponent,
                AddActivatedNumberModalComponent, ConfirmPasswordModalComponent, InformationMessageModalComponent,
                InputModalComponent, RoutingModalComponent, EditCcModalComponent, SelectCCModalComponent, ShippingAddressModalComponent,
                SimReplacementModalComponent , eSimReplacementModalComponent,
                AlertSecurityModalComponent, IosDataSetupModalComponent, CompatibilitySkipModalComponent,
                TrackingModalComponent, successModalComponent, InstractionsFiveGIos17ModalComponent,RecaptchaVerificationComponent,GoogleLoginComponent],
        imports: [
                SwiperModule, CommonModule, WidgetsModule, FormsModule, ReactiveFormsModule, MatAutocompleteModule,GoogleSigninButtonModule
        ],
        exports: [ConfirmMessageModalComponent, ManagePaymentModalContextComponent, PhoneNotImpactedModalComponent,
                ManageAddressesModalComponent, ShippingAddressModalComponent,
                AddActivatedNumberModalComponent, ConfirmPasswordModalComponent, InformationMessageModalComponent,
                InputModalComponent, RoutingModalComponent, EditCcModalComponent, SelectCCModalComponent,
                SimReplacementModalComponent, eSimReplacementModalComponent, IosDataSetupModalComponent, CompatibilitySkipModalComponent,
                TrackingModalComponent, successModalComponent, InstractionsFiveGIos17ModalComponent,RecaptchaVerificationComponent,GoogleLoginComponent]
})
export class ModalsModule {
}
