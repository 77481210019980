<div class="modal">
  <div class="container model-container {{settings.customClass || ''}}">
    <img src="assets/icon/popup-close-icon.svg" (click)="closeDialog()" class="close-icon"
      width="32" height="32" alt="Close Icon">
    <div class="modal-header">
      <h4 class="modal-heading">{{settings.title || ''}}</h4>
    </div>
    <div class="modal-body">
      <div class="modal-content">
        <div class="row">
          <div *ngIf="!settings.enableHTML" class="twelve">
            {{context.message}}
          </div>
          <div *ngIf="settings.enableHTML" [innerHTML]="context.message" class="twelve"></div>
        </div>
        <div class="row modal-actions">
          <button class="button primary" (click)="OK()">{{settings.okText || 'OK'}}</button>
          <button class="button secondary" (click)="cancel()">{{settings.cancelText || 'Cancel'}}</button>
        </div>
      </div>
    </div>
  </div>
</div>