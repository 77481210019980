<div class="bring-phone-page">
    <section class="banner-section">
        <picture class="banner-image-container">
            <source width="639" height="634" srcset='assets/img/banners/bring-phone-banner-mobile.webp'
                media="(max-width: 639.9px)">
            <source width="639" height="634" srcset='assets/img/banners/bring-phone-banner-mobile.png'
                media="(max-width: 639.9px)">
            <source width="1024" height="634" srcset='assets/img/banners/bring-phone-banner-tablet.webp'
                media="(max-width: 1024.9px)">
            <source width="1024" height="634" srcset='assets/img/banners/bring-phone-banner-tablet.png'
                media="(max-width: 1024.9px)">
            <source width="1439" height="618" srcset='assets/img/banners/bring-phone-banner-desktop-bp.webp'
                media="(max-width: 1439.9px)">
            <source width="1439" height="618" srcset='assets/img/banners/bring-phone-banner-desktop-bp.png'
                media="(max-width: 1439.9px)">
            <source width="2000" height="470" srcset='assets/img/banners/bring-phone-banner-desktop.webp'
                media="(min-width: 1440px)">
            <source width="2000" height="470" srcset='assets/img/banners/bring-phone-banner-desktop.png'
                media="(min-width: 1440px)">
            <img width="2000" height="470" src="assets/img/banners/bring-phone-banner-desktop.png"
                alt="Bring Phone Banner Image">
        </picture>
        <div class="banner-text-content page-section">
            <h1 class="header">Bring Your Phone<img width="32" height="39" src="assets/img/bring-phone-header-star.svg"
                    alt="Header Star Image"></h1>
            <h4 class="sub-header">FreeUp your own phone and the number you love.</h4>
            <div class="get-started-button" *ngIf="!showCompatibilityform" @fadeSlideButton>
                <button class="button primary" aria-label="Get started" id="get-started"
                    (click)="showCheckCompatibility()">Get Started</button>
            </div>
            <div class="check-compatibility-box" *ngIf="!!showCompatibilityform" @fadeSlide>
                <form [formGroup]="compatibilityForm" id="compatibilityFormDiv">
                    <div class="inputs-flex">
                        <div class="form-row">
                            <p class="smaller"><b>Enter Serial Device Number:</b></p>
                            <p class="caption">Dial: *#06# on your phone to get your IMEI</p>
                            <div class="form-field">
                                <label class="outlined-label-input">
                                    <input placeholder=" " required formControlName="imei" name="equipmentNumber"
                                        id="equipmentNumber" (paste)="onPaste($event)" restrictNumbers minlength="11"
                                        maxlength="18" />
                                    <span>Enter your IMEI</span>
                                </label>
                                <label class="validation-message" id="required-imei-msg"
                                    *ngIf="compatibilityForm.controls.imei.hasError('required') && compatibilityForm.controls.imei.touched">
                                    IMEI is required
                                </label>
                                <label class="validation-message" id="invalid-imei-msg"
                                    *ngIf="compatibilityForm.controls.imei.hasError('minlength')">
                                    Invalid serial, it should be between 11-18 digits
                                </label>
                            </div>
                        </div>
                        <div class="form-row">
                            <p class="smaller"><b>Enter Full Address:</b></p>
                            <p class="caption">Street Number, Address Line, City, State, ZIP Code</p>
                            <div class="form-field">
                                <label class="outlined-label-input flowed">
                                    <input formControlName="address" type="search" list-formatter="description"
                                        data-cy="addressRef" type="search" class="geolocate" placeholder=" "
                                        [matAutocomplete]="auto" required (input)="changedAddress()" />
                                    <span>Enter your Address here</span>
                                    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addressDetails($event)">
                                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                                            {{ option?.description }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </label>
                                <label class="validation-message"
                                    *ngIf="compatibilityForm.controls.address.touched && compatibilityForm.controls.address.hasError('required')"
                                    id="required-address-msg">Address is required</label>
                                <label class="validation-message"
                                    *ngIf="!displayedAddressModel && !!compatibilityForm.controls.address.value"
                                    id="invalid-address-msg">Please select address from the autocomplete list</label>
                            </div>
                        </div>
                    </div>
                    <div class="check-compatibility-actions">
                        <button class="button primary" type="submit" id="check-phone-button"
                            aria-label="Check Compatibility" (click)="checkPhoneCompatibility()">Check
                            Compatibility</button>
                        <button type="button" class="button tertiary" aria-label="Cancel"
                            (click)="hideCheckCompatibility()" id="hide-compatibilty-button">Cancel</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
    <section class="try-us-section page-section">
        <h2 class="title">Try Us Out Today!</h2>
        <h5 class="sub-title">How to start?</h5>
        <div class="features-part">
            <div class="features-flex">
                <div class="feature-point">
                    <div class="point-icon choose">
                        <img src="assets/icon/choose-plan.svg" width="70" height="70" alt="Choose Plan Icon">
                    </div>
                    <div class="point-content">
                        <h4 class="title">Choose Your Plan</h4>
                        <p class="desc mobile">FreeUp offers affordable monthly and multi-month plans for the greatest
                            savings.
                        </p>
                    </div>
                </div>
                <div class="feature-point">
                    <div class="point-icon truck">
                        <img src="assets/icon/sim-card-shipping.svg" width="80" height="51"
                            alt="SIM Card Shipping Icon">
                    </div>
                    <div class="point-content">
                        <h4 class="title">Get a FREE SIM Card</h4>
                        <p class="desc">Once you’ve picked a plan, FreeUp will send you a free SIM Card, FREE Shipping!
                        </p>
                    </div>
                </div>
            </div>
            <div class="features-flex">
                <div class="feature-point">
                    <div class="point-icon transfer">
                        <img src="assets/icon/transfer-card-number.svg" width="66" height="72"
                            alt="Transfer Number Icon">
                    </div>
                    <div class="point-content">
                        <h4 class="title">Keep your Number</h4>
                        <p class="desc">On activation, you can transfer your number from another carrier to FreeUp. Or
                            request a new number for your area code.</p>
                    </div>
                </div>
                <div class="feature-point">
                    <div class="point-icon phone">
                        <img src="assets/icon/phone-checked.svg" width="56" height="72" alt="Phone Checked Icon">
                    </div>
                    <div class="point-content">
                        <h4 class="title">Keep The Phone You Love!</h4>
                        <p class="desc">FreeUp supports most 5G / 4G LTE unlocked phones.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="what-we-offer-section page-section">
        <h2 class="title">What We Offer:</h2>
        <h5 class="sub-title">Choose what suits you from the variety of our subscription plans:</h5>
        <div class="offers-part">
            <div class="offer">
                <div class="offer-img">
                    <img src="assets/img/bring-phone-offer-1.svg" width="280" height="280" alt="Offer Image">
                </div>
                <div class="offer-content">
                    <h5 class="title">Browse our 3-Month Introductory Offer!</h5>
                    <p class="desc smaller">Get your service for <b>3-Month</b> with special discounted intro prices!
                    </p>
                    <a (click)="goToPlans()">Check 3-Month Plans</a>
                </div>
            </div>
            <div class="offer reverse">
                <div class="offer-img">
                    <img src="assets/img/bring-phone-offer-2.svg" width="280" height="280" alt="Offer Image">
                </div>
                <div class="offer-content">
                    <h5 class="title">Browse Annual Plans!</h5>
                    <p class="desc smaller">Get your service for a full year with <b>30% off</b>, paid once!</p>
                    <a (click)="goToPlans(12)">Check Annual Plans</a>
                </div>
            </div>
            <div class="offer">
                <div class="offer-img">
                    <img src="assets/img/bring-phone-offer-3.svg" width="280" height="280" alt="Offer Image">
                </div>
                <div class="offer-content">
                    <h5 class="title">Browse Our Other Plan Offers!</h5>
                    <p class="desc smaller">We have a variety of <b>1-Month</b> and <b>6-Month plans</b> that you can
                        choose from!</p>
                    <a (click)="goToPlans(1)">Check Other Plans</a>
                </div>
            </div>
        </div>
    </section>
    <section class="faqs-section page-section">
        <h2 class="faqs-title">FAQs</h2>
        <h5 class="faqs-sub-title">Got question? We got answers!</h5>
        <div class="faqs-margins">
            <div class="questions">
                <div class="question" *ngFor="let qst of firstThreeQuestions">
                    <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                        [class.active]="qst?.fields?.questionId == questionId"
                        [isActive]="qst?.fields?.questionId == questionId"
                        (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                        <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                    </app-question-answer>
                </div>
                <div *ngIf="!!showMore">
                    <div *ngFor="let qst of restQuestions">
                        <app-question-answer question="{{qst?.fields?.questionText}}" [id]="qst?.fields?.questionId"
                            [class.active]="qst?.fields?.questionId == questionId"
                            [isActive]="qst?.fields?.questionId == questionId"
                            (click)="toggleActive(qst?.fields?.questionId,qst?.fields?.answerId)">
                            <p (click)="collapsed=true" id="{{qst?.fields?.answerId}}"></p>
                        </app-question-answer>
                    </div>
                </div>
            </div>
        </div>
        <div class="more-less-faqs-part">
            <div class="more-less" (click)="showMore = !showMore">
                <a>{{ !showMore ? 'More FAQs' : 'Less FAQs' }}</a>
                <img src="/assets/icon/circle-down-arrow-icon.svg" alt="Circle Arrow Icon" [class.rotate]="!!showMore"
                    width="20" height="20">
            </div>
        </div>
        <div class="bring-phone-info">
            <p class="caption">Most unlocked 5G / 4G LTE phones will work on the FreeUp network, however, we don’t
                have control over the availability of the features we provide that are not available from your
                unlocked phone.</p>
            <p class="caption margin">Unfortunately, some features like <b>Wi-Fi calling, VoLTE, MMS</b> may not
                work on our network. FreeUP Mobile cannot and does not guarantee the functionality or performance of
                any handsets or devices for use on the FreeUP Mobile network. Nor do we guarantee the functionality
                of any handsets or devices. As a courtesy, we <b>provide Internet and picture messaging (MMS)
                    settings</b> for some devices. FreeUP Mobile cannot guarantee the effectiveness of these
                settings, nor their placement in the device and therefore does not assume any liability for the lack
                of effectiveness of these settings for the devices.</p>
            <p class="caption">Wi-Fi Calling requires Wi-Fi Calling capable smartphone and Wi-Fi network. Once
                enabled, phone automatically makes/receives calls/text messages over Wi-Fi in the U.S. If wireless
                network coverage is weak or unavailable, or outside the Domestic Coverage Area anytime you connect
                to a Wi-Fi network. Wi-Fi Calling is available on select devices. Use of Wi-Fi Calling may be
                restricted in some countries. If you move in or out of Wi-Fi coverage while on a Wi-Fi call, your
                call will disconnect unless you have AT&T HD Voice coverage (911 calls will disconnect even if you
                are within HD Voice coverage). HD Voice not available everywhere. To <b>enable Wi-Fi
                    Calling,</b> you must have a <b>wireless account provisioned for HD Voice</b>.
                Cannot use Wi-Fi Calling to call <b>211, 311, 511, and 811 <a
                        [routerLink]="[SUPPORT_ROUTE_URLS.BASE + '/' + SUPPORT_ROUTE_URLS.FAQS + '/wifi-calling']">Learn
                        more</a></b></p>
        </div>
    </section>
</div>